body {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
@font-face {
  font-family: "Inter";
  src: url("./assets/font/Inter.ttf");
}
@font-face {
  font-family: "Aeonik";
  src: url("./assets/font/Aeonik-Light.ttf");
}
@font-face {
  font-family: "Acumin";
  src: url("./assets/font/acumin-pro.ttf");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* * {
  color: #f5f4f1;
} */
.MuiPaper-root {
  background-color: transparent !important;
}
