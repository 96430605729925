pre {
  overflow: auto;
}

circle:focus {
  outline: none;
}

input:focus,
select:focus {
  outline: none;
  background-color: color(var(--blue) a(0.125)) !important;
}

select {
  cursor: pointer;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button:disabled,
select:disabled {
  border-color: transparent !important;
  opacity: 0.5 !important;
}

button:focus,
a:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--blue) !important;
}
