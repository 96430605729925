.custom_select {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 10px 0;
}
.custom_select:after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid rgb(255, 255, 255);
  position: absolute;
  top: 40%;
  right: 10px;
  content: "";
  z-index: 98;
}
